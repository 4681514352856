var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-card-header',[_c('h3',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.providerContactData.proveedorTiContactoId == 0 ? _vm.$t("Routes.Contact") : _vm.$t("Routes.Contact"))+" ")])]),_c('b-card-body',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AppTI.contact.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTI.contact.name'),"label-for":"type"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('AppTI.contact.name'),"type":"text"},model:{value:(_vm.providerContactData.nombre),callback:function ($$v) {_vm.$set(_vm.providerContactData, "nombre", $$v)},expression:"providerContactData.nombre"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AppTI.contact.job'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTI.contact.job'),"label-for":"type"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('AppTI.contact.job'),"type":"text"},model:{value:(_vm.providerContactData.puesto),callback:function ($$v) {_vm.$set(_vm.providerContactData, "puesto", $$v)},expression:"providerContactData.puesto"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AppTI.contact.phone'),"rules":"required|phoneNumber|min:10|max:18"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTI.contact.phone'),"label-for":"type"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('AppTI.contact.phone'),"type":"number"},model:{value:(_vm.providerContactData.telefono),callback:function ($$v) {_vm.$set(_vm.providerContactData, "telefono", $$v)},expression:"providerContactData.telefono"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AppTI.contact.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTI.contact.email'),"label-for":"email"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('AppTI.contact.email'),"type":"email"},model:{value:(_vm.providerContactData.correo),callback:function ($$v) {_vm.$set(_vm.providerContactData, "correo", $$v)},expression:"providerContactData.correo"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"float-right mt-2 mb-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[(_vm.addingProvider)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.providerContactData.proveedorTiContactoId == 0 ? _vm.$t("Lists.Add") : _vm.$t("Lists.Edit"))+" ")],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }