import axios from '@axios'
import common from '@/libs/common'
import defaultService from './_default.service'

export default function tecnologyInformationService() {
  const defaultMethods = defaultService('TecnologiasInformacion')
  const { throwError, throwSuccess } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api/TecnologiasInformacion`

  const fetchSoftwares = (data, callback) => defaultMethods.fetch('Software', {}, data, callback)
  const fetchSoftware = (softwareId, callback) => defaultMethods.fetch('Software/%{softwareId}', { softwareId }, {}, callback)
  const fetchDocumentsSoftware = (id, callback) => defaultMethods.fetch('Software/%{id}/Documento', { id }, {}, callback)
  const deleteDocumenSoftware = (id, docId, callback) => defaultMethods.remove('Software/%{id}/Documento/%{docId}', { id, docId }, {}, callback)
  const fetchProviders = (data, callback) => defaultMethods.fetch('Proveedor', {}, data, callback)
  const fetchProvider = (proveedorTiId, callback) => defaultMethods.fetch('Proveedor/%{proveedorTiId}', { proveedorTiId }, {}, callback)
  const deleteProvider = (proveedorTiId, callback) => defaultMethods.remove('Proveedor/%{proveedorTiId}', { proveedorTiId }, true, callback)
  const fetchContacts = (id, data, callback) => defaultMethods.fetch('ProveedorContactos/%{id}', { id }, data, callback)
  const fetchContact = (id, callback) => defaultMethods.fetch('ProveedorContacto/%{id}', { id }, {}, callback)
  const deleteContact = (id, callback) => defaultMethods.remove('ProveedorContacto/%{id}', { id }, true, callback)
  const fetchTypeEquipments = (data, callback) => defaultMethods.fetch('TipoEquipo', {}, data, callback)
  const fetchTypeEquipment = (id, callback) => defaultMethods.fetch('TipoEquipo/%{id}', { id }, {}, callback)
  const fetchBrands = (data, callback) => defaultMethods.fetch('EquipoMarca', {}, data, callback)
  const fetchBrand = (id, callback) => defaultMethods.fetch('EquipoMarca/%{id}', { id }, {}, callback)
  const fetchModels = (data, callback) => defaultMethods.fetch('EquipoModelos', {}, data, callback)
  const fetchModel = (id, callback) => defaultMethods.fetch('EquipoModelos/%{id}', { id }, {}, callback)
  const fetchEquipments = (data, callback) => defaultMethods.fetch('Equipo', {}, data, callback)
  const fetchEquipment = (id, callback) => defaultMethods.fetch('Equipo/%{id}', { id }, {}, callback)
  const fetchLogEquipment = (id, callback) => defaultMethods.fetch('Equipo/%{id}/Bitacora', { id }, {}, callback)
  const fetchDocumentsEquipment = (id, callback) => defaultMethods.fetch('Equipo/%{id}/Documento', { id }, {}, callback)
  const deleteDocumenEquipment = (id, docId, callback) => defaultMethods.remove('Equipo/%{id}/Documento/%{docId}', { id, docId }, {}, callback)
  const fetchEquipmentsBrands = (tipoMarcaId, data, callback) => defaultMethods.fetch('EquipoModelRelacionado/%{tipoMarcaId}', { tipoMarcaId }, data, callback)
  /* Standard prefix names:
    Post: create, Get: fetch,
    Put: update,  Patch: partialUpdate,
    Delete: delete
  */
  const createSoftware = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/Software`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const updateSoftware = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/Software/${data.softwareId}`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const fetchDocumentSoftware = (id, docId, callback) => {
    axios
      .get(`${API_URL}/Software/${id}/Documento/${docId}`, { responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const createDocumentSoftware = (id, data, callback) => {
    axios
      .post(`${API_URL}/Software/${id}/Documento`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => {
        throwError(error)
      })
  }
  const createProvider = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/Proveedor`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const updateProvider = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/Proveedor/${data.proveedorTiId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const createContact = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/ProveedorContacto`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const updateContact = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/ProveedorContacto/${data.proveedorTiContactoId}`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const createTypeEquipment = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/TipoEquipo`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })

  const updateTypeEquipment = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/TipoEquipo/${data.tipoEquipoId}`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })

  const createBrand = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/EquipoMarca`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const updateBrand = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/EquipoMarca/${data.tipoMarcaId}`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const createModel = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/EquipoModelos`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const updateModel = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/EquipoModelos/${data.tipoEquipoMarcaModeloId}`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const createEquipment = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/Equipo`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const updateEquipment = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/Equipo/${data.equipoId}`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })

  const fetchDocumentEquipment = (id, docId, callback) => {
    axios
      .get(`${API_URL}/Equipo/${id}/Documento/${docId}`, { responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const createDocumentEquipment = (id, data, callback) => {
    axios
      .post(`${API_URL}/Equipo/${id}/Documento`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => {
        throwError(error)
      })
  }

  // ===================================================================|
  //   Bank Count --------------------------------------------------------------------------------------------|
  // ===================================================================|

  // ===================================================================|
  //   Bank Count --------------------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchAccountBankProviders = (data, callback) => defaultMethods.fetch('ProveedorCuentaBancaria', {}, data, callback)
  const fetchAccountBankProvider = (id, callback) => defaultMethods.fetch('ProveedorCuentaBancaria/%{id}', { id }, {}, callback)
  const updateAccountBankProvider = (id, data, callback) => defaultMethods.update('ProveedorCuentaBancaria/%{id}', { id }, true, data, callback)
  const createAccountBankProvider = (data, callback) => defaultMethods.create('ProveedorCuentaBancaria', {}, true, data, callback)
  const deleteAccountBankProvider = (id, callback) => defaultMethods.remove('ProveedorCuentaBancaria/%{id}', { id }, true, callback)

  return {
    fetchSoftwares,
    fetchSoftware,
    createSoftware,
    updateSoftware,
    fetchDocumentsSoftware,
    fetchDocumentSoftware,
    createDocumentSoftware,
    deleteDocumenSoftware,
    fetchProviders,
    fetchProvider,
    createProvider,
    updateProvider,
    deleteProvider,
    fetchContacts,
    createContact,
    updateContact,
    fetchContact,
    deleteContact,
    fetchTypeEquipments,
    fetchTypeEquipment,
    createTypeEquipment,
    updateTypeEquipment,
    fetchModels,
    fetchModel,
    createModel,
    updateModel,
    fetchBrands,
    fetchBrand,
    createBrand,
    updateBrand,
    fetchEquipments,
    fetchEquipment,
    fetchLogEquipment,
    createEquipment,
    updateEquipment,
    fetchDocumentsEquipment,
    fetchDocumentEquipment,
    createDocumentEquipment,
    deleteDocumenEquipment,
    fetchAccountBankProviders,
    fetchAccountBankProvider,
    updateAccountBankProvider,
    createAccountBankProvider,
    deleteAccountBankProvider,
    fetchEquipmentsBrands,
  }
}
