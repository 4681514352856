<template>
  <div>
    <b-card>
      <b-card-header>
        <h3 class="mb-2">
          {{
            providerContactData.proveedorTiContactoId == 0
              ? $t("Routes.Contact")
              : $t("Routes.Contact")
          }}
        </h3>
      </b-card-header>

      <b-card-body>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- -----------------NOMBRE----------------------------- -->

            <b-row>
              <b-col cols="12" md="6">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('AppTI.contact.name')"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('AppTI.contact.name')"
                    label-for="type"
                  >
                    <b-form-input
                      v-model="providerContactData.nombre"
                      :placeholder="$t('AppTI.contact.name')"
                      type="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- -----------------puesto----------------------------- -->

              <b-col cols="12" md="6">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('AppTI.contact.job')"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('AppTI.contact.job')"
                    label-for="type"
                  >
                    <b-form-input
                      v-model="providerContactData.puesto"
                      :placeholder="$t('AppTI.contact.job')"
                      type="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- -----------------telefono----------------------------- -->

              <b-col cols="12" md="6">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('AppTI.contact.phone')"
                  rules="required|phoneNumber|min:10|max:18"
                >
                  <b-form-group
                    :label="$t('AppTI.contact.phone')"
                    label-for="type"
                  >
                    <b-form-input
                      v-model="providerContactData.telefono"
                      :placeholder="$t('AppTI.contact.phone')"
                      type="number"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- -----------------CORREO----------------------------- -->

              <b-col cols="12" md="6">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('AppTI.contact.email')"
                  rules="required|email"
                >
                  <b-form-group
                    :label="$t('AppTI.contact.email')"
                    label-for="email"
                  >
                    <b-form-input
                      v-model="providerContactData.correo"
                      :placeholder="$t('AppTI.contact.email')"
                      type="email"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- -----------------Botton----------------------------- -->

            <b-row class="float-right mt-2 mb-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <b-spinner v-if="addingProvider" small class="mr-1" />
                {{
                  providerContactData.proveedorTiContactoId == 0
                    ? $t("Lists.Add")
                    : $t("Lists.Edit")
                }}
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BForm,
  BFormInput,
  BFormGroup,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required, max } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import tiService from '@/services/informationTecnology.service'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BForm,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,

    ValidationObserver,
    ValidationProvider,
  },
  props: {
    modalCall: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      required,
      max,
    }
  },
  setup(props, { emit }) {
    const addingProvider = ref(false)
    const providerContactData = ref({
      proveedorTiContactoId: 0,
      nombre: '',
      puesto: '',
      telefono: '',
      correo: '',
    })

    const providerBlank = {
      proveedorTiContactoId: 0,
      nombre: '',
      puesto: '',
      telefono: '',
      correo: '',
    }

    const {
      fetchContacts, fetchContact, createContact, updateContact,
    } = tiService()

    const resetProviderData = () => {
      providerContactData.value = JSON.parse(JSON.stringify(providerBlank))
    }

    if (router.currentRoute.params.proveedorTiContactoId) {
      fetchContacts(router.currentRoute.params.proveedorTiContactoId, data => {
        providerContactData.value = data
      })
      fetchContact(router.currentRoute.params.proveedorTiContactoId, data => {
        providerContactData.value = data
      })
    } else {
      providerContactData.value = JSON.parse(JSON.stringify(providerBlank))
      providerContactData.value.proveedorTiId = router.currentRoute.params.proveedorTiId
    }

    const { refFormObserver, resetForm, getValidationState } = formValidation(resetProviderData)

    const onSubmit = () => {
      addingProvider.value = true
      if (providerContactData.value.proveedorTiContactoId === 0) {
        createContact(providerContactData.value).then(() => {
          addingProvider.value = false
          if (props.modalCall) {
            emit('find-records')
          } else {
            router.push({ name: 'apps-provider-details' })
          }
        })
      } else {
        updateContact(providerContactData.value).then(() => {
          addingProvider.value = false
          router.push({ name: 'apps-provider-details' })
        })
      }
    }
    return {
      refFormObserver,
      resetForm,
      getValidationState,
      providerContactData,
      addingProvider,
      onSubmit,
    }
  },
}
</script>
